import React from "react"
import styled from "styled-components"
import Title from "./Title"
import SocialMediaLinks from "./SocialMediaLinks"
import DiveIn from "./DiveIn"
import OverlayBottom from "../core/OverlayBottom"
import { Fade } from "react-reveal"
import Img from "gatsby-image"

const BackgroundStyled = styled.div`
  position: relative;
  height: 900px;
  margin-top: -138px;
  text-align: center;
`
const BackgroundImageStyled = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
`
const SocialMediaLinksStyled = styled.div`
  display: none;
  @media (min-width: 768px) {
    display: block;
  }
`
const VideoBackground = styled.div`
  height: 900px;
  overflow: hidden;
  padding: 0;
  position: relative;
  z-index: -1;
  iframe {
    box-sizing: border-box;
    height: 56.25vw;
    left: 50%;
    min-height: 900px;
    min-width: 100vw;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    width: 250vh;
  }
`

const ImageBackground = styled.div`
  height: 900px;
`

const StyledImage = styled(Img)`
  height: 100% !important;
`

const HomeHero = ({ heroVideo, heroImage }) => {

  return (
    <BackgroundStyled>
      <BackgroundImageStyled>
        <Title />
        <Fade bottom duration={750} delay={500}>
          <DiveIn />
        </Fade>
        <SocialMediaLinksStyled>
          <SocialMediaLinks hero />
        </SocialMediaLinksStyled>
        <OverlayBottom />
      </BackgroundImageStyled>
      {heroVideo !== "image" ? (
        <VideoBackground>
          <ImageBackground>
            <StyledImage fluid={heroImage.fluid} alt="Lost at sea hero placeholder" loading={"eager"}/>
          </ImageBackground>
          <iframe
            src={`${heroVideo}?autoplay=1&muted=1&background=1&title=0&byline=0&portrait=0&color=ffffff`}
            title="Lost At Sea Hero Video"
            frameBorder="0"
            allow="fullscreen; autoplay"
            allowFullScreen={true}
            id="hero-video"
          />
        </VideoBackground>
      ) : (
        <ImageBackground>
          <StyledImage fluid={heroImage.fluid} alt="Lost at sea hero" />
        </ImageBackground>
      )}
    </BackgroundStyled>
  )
}

export default HomeHero
