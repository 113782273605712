import React from 'react';
import styled from 'styled-components';
import LineImg from '../../resources/images/ui/home-hero-line.svg';
import LineImgMobile from '../../resources/images/ui/home-hero-line-mobile.svg';
import HorizontalLine from '../../resources/images/horizontal-broken-line.svg';
import Anchor from '../../resources/images/ui/anchor.svg';
import fonts from '../../styles/fonts';

const DesktopOnly = styled.div`
    display:block;
    @media(max-width: 768px) {
        display:  none;
    }
`;
const MobileOnly = styled.div`
    display:block;
    @media(min-width: 769px) {
        display:  none;
    }
`;

const StyledDiveIn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 45px;
    @media (min-width: 768px) {
        margin-top: 90px;
    }
`;

const WhiteLine = styled.img`
    padding-bottom: 10px;
    padding-top: 20px;
`;
const HorizontalLineStyled = styled.img`
    width: 64px;
    @media (min-width: 768px) {
        width: 80px;
    }
`;
const AnchorStyled = styled.img`
    width: 23px;
    heihgt: 27px;
`;
const DiveInText = styled.div`
    padding-left: 5px;
    text-align: center;
    font-size: 12px;
    ${fonts.gilroyBold};
    color: white;
    text-transform: uppercase;
    letter-spacing: 6px;
    padding-top: 20px;
    cursor: default;
`;


const DiveIn = () => {
    return (<StyledDiveIn>
        <HorizontalLineStyled src={HorizontalLine} />
        <DesktopOnly> <WhiteLine src={LineImg} />  </DesktopOnly>
        <MobileOnly><WhiteLine src={LineImgMobile} /></MobileOnly>
        <AnchorStyled src={Anchor} />
        <DiveInText>Dive In</DiveInText>
    </StyledDiveIn>
    )
}

export default DiveIn;