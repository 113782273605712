import React from 'react';
import styled from 'styled-components';
import { Row, Col, Container } from 'styled-bootstrap-grid';
import fonts from '../../styles/fonts';
import ProductIntroBackgroundImg from '../../resources/images/home/product-intro-block-background.jpg';
import ProductList from './ProductList';
import ProductCarousel from './ProductCarousel';
import Fade from 'react-reveal/Fade';
import Img from "gatsby-image";
import WavesImage from "../../resources/images/icons/waves.svg";

const ProductBackground = styled.div`
    background-color: #020913;
    width: 100%;
`;
const IntroContainer = styled(Container)`
    padding-top: 140px; 
    @media(max-width: 768px) {
        padding: 0;
    }
`;
const ColStyled = styled(Col)`
    padding-left: 0;
    padding-right: 0;
        @media(min-width: 768px) {
            display: ${props => props.mobileonly ? "none" : "block"}
        }
        @media(max-width: 769px) {
            display: ${props => props.desktoponly ? "none" : "block"}
        }
    `;
const RowStyled = styled(Row)`
    margin-left: 0;
    margin-right: 0;
    width: ${props => props.fullwidth ? "100%" : ""};
    height: ${props => props.fullwidth ? "100%" : ""};
`;
const Intro1 = styled(Img)`
    height: 178px;
    width: 268px;
`;
const SmallImgContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
`;
const IntroTitle = styled.div`
    position: relative;
    z-index: 10;
    padding-top: 40px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    @media(min-width: 768px) {
        align-items: center;
        justify-content: center;
        padding-top: 70px;
    }
`;
const IntroWhite = styled.h2`
    text-transform: uppercase;
    color: white;
    font-size: 30px;
    line-height: 32px;
    ${fonts.swissBlackExtended};
    letter-spacing: 2px;
    padding-left: 30px;
    padding-right: 60px;
    @media(min-width: 768px) {
        font-size: 60px;
        padding-left: 0;
        padding-right: 0;
        letter-spacing: 6px;
        line-height: 62px;
    }
    @media(max-width: 362px) {
        font-size: 28px;
        padding-left: 4px;
    }
`;
const IntroOutline = styled.h2`
    text-transform: uppercase;
    color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #9CA8B5;
    font-size: 30px;
    line-height: 33px;
    letter-spacing: 2px;
    ${fonts.swissBlackExtended};
    padding-left: 30px;
    @media(min-width: 768px) {
        -webkit-text-stroke-width: 1.5px;
        font-size: 61px;
        line-height: 63px;
        padding-left: 0;
        padding-right: 0;
        letter-spacing: 6px;
    }
    @media(max-width: 362px) {
        font-size: 28px;
        padding-left: 4px;
    }
`;
const BigImgContainer = styled.div`
    position: relative;
    z-index: 9;
    height: 100%;
    margin-top: -10px;
    margin-bottom: 67px;
    padding-left: 78px;
    @media(min-width: 768px) {
        margin-top: -21px;
        margin-bottom: 80px;
        height: 561px;
    }
`;
const Intro2 = styled(Img)`
    height: 281px;
    max-width: 212px;
    width: 100%;
    @media(min-width: 768px) {
        height: 481px;
        max-height: 481px;
        max-width: 363px;
    }
    @media (min-width: 991px)  {
    }
`;
const IntroText = styled.div`
    z-index: 10;
    ${fonts.gilroyRegular};
    font-size: 15px;
    color: rgb(158, 168, 180);
    line-height: 21px;
    padding-right: 25px;
    padding-left: 25px;
    padding-bottom: 100px;
    @media(min-width: 450px) {
        padding-bottom: 100px;
    }
    @media(min-width: 768px) {
        color: #9CA8B5;
    }
    @media (min-width: 991px)  {
        padding-right: 95px;
        padding-bottom: 50px;
    }
`;
const IntroTextContainer = styled.div`
    padding-top: 75px;
    position: relative;
    z-index: 99;
    @media(max-width: 768px) {
        padding-top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
    }
`;
const SpaceDiv = styled.div`
    padding: 10px;
`;
const CarouselBackground = styled.div`
    width: 100vw;
    height: 916px;
    background: url(${ProductIntroBackgroundImg}) no-repeat;
    background-size: 100% 100%;
    margin-top: -150px;
`;
const CarouselContainer = styled.div`
    width: 100vw;
    position: relative;
`;
const DesktopOnly = styled.div`
    display:block;
    @media(max-width: 768px) {
        display:  none;
    }
`;
const MobileOnly = styled.div`
    display:block;
    @media(min-width: 769px) {
        display:  none;
    }
`;
const DesktopOnlyCarousel = styled.div`
    display:block;
    @media(max-width: 991px) {
        display:  none;
    }
`;
const MobileOnlyCarousel = styled.div`
    display:block;
    @media(min-width: 992px) {
        display:  none;
    }
`;

const Waves = styled.img`
  position: absolute;
  bottom: 50px;
  left: 249px;
  z-index: 2;
  
  @media(min-width: 992px){
    bottom: 147px;
    left: 400px;
  }
`;

const ProductIntro = ({ products, IntroImg1, IntroImg2 }) => {

    return (
      <ProductBackground>
          <IntroContainer>
              <RowStyled>
                  <ColStyled md={8}>
                      <IntroTitle>
                          <Fade duration={750} delay={500}>
                              <IntroWhite>Let's raise a glass</IntroWhite>
                          </Fade>
                          <Fade duration={750} delay={750}>
                              <IntroOutline>To life's adventurers</IntroOutline>
                          </Fade>
                      </IntroTitle>
                  </ColStyled>
                  <ColStyled md={4} desktoponly>
                      <SmallImgContainer>
                          <Fade bottom duration={1000} delay={250}>
                              <Intro1 fluid={IntroImg1.childImageSharp.fluid} alt={""}/>
                          </Fade>
                      </SmallImgContainer>
                  </ColStyled>
              </RowStyled>
              <RowStyled>
                  <ColStyled md={7}>
                      <DesktopOnly>
                          <Fade bottom duration={1000}>
                              <BigImgContainer>
                                  <Intro2 fluid={IntroImg2.childImageSharp.fluid} alt={""}/>
                                  <Waves src={WavesImage} alt={"Waves"}/>
                              </BigImgContainer>
                          </Fade>
                      </DesktopOnly>
                      <MobileOnly>
                          <BigImgContainer>
                              <Intro2 fluid={IntroImg2.childImageSharp.fluid} alt={""}/>
                              <Waves src={WavesImage} alt={"Waves"}/>
                          </BigImgContainer>
                      </MobileOnly>
                  </ColStyled>
                  <ColStyled md={5}>
                      <IntroTextContainer>
                          <Fade duration={500} delay={500}>
                              <IntroText>
                                  <p>The ones who ask themselves, “what’s round that corner, over that horizon, beyond
                                      that ocean?”. The ones who get off their backside and find out. The trailblazers,
                                      the pioneers, the ones who know it can’t be done but do it anyway. For them it’s
                                      all about the challenge.</p>
                                  <SpaceDiv/>
                                  <p>Sure, they risk getting lost, but the alternative is something far worse – the
                                      safety of mediocrity. So here’s to those who voyage into the unknown, find the way
                                      forward and choose a life less ordinary. They make our world a better place.</p>
                              </IntroText>
                          </Fade>

                      </IntroTextContainer>
                  </ColStyled>
              </RowStyled>
          </IntroContainer>
          <CarouselContainer>
              <DesktopOnlyCarousel>
                  <ProductList products={products}/>
              </DesktopOnlyCarousel>
              <MobileOnlyCarousel>
                  <ProductCarousel products={products}/>
              </MobileOnlyCarousel>
              <CarouselBackground/>
          </CarouselContainer>
      </ProductBackground>
    )
}

export default ProductIntro
