import React, { Component } from 'react';
import styled from 'styled-components';
import Shipmate from './Shipmates'
import RightArrow from '../../resources/images/ui/right-arrow.svg';
import LeftArrow from '../../resources/images/ui/left-arrow.svg';
import UpArrow from '../../resources/images/ui/up-arrow.svg';
import DownArrow from '../../resources/images/ui/down-arrow.svg';
import { Zoom } from 'react-reveal';
import Slider from "react-slick";


const Shipmates = styled.div`
    bottom: 100px;
    z-index: 10;
    @media(max-width: 991px) {
        padding: 0;
    }
`;
const CarouselArrows = styled.div`
    padding-top: 35px;
    text-align: center;
    @media(min-width: 991px) {
        display: flex;
        flex-direction: column;
        position: absolute;
        right: 60px;
        top: 50%;
        transform: translateX(-50%); 
    }
`;
const LeftArrowStyled = styled.img`
    background: transparent;
    height: 6px;
    width: 40px;
    padding-right: 15px;
    @media(min-width: 991px) {
        height: 40px;
        width: 6px;
        padding-top: 15px;
        padding-right: 0
    }
`;
const RightArrowStyled = styled.img`
    background: transparent;
    height: 6px;
    width: 40px;
    padding-left: 15px;
    @media(min-width: 991px) {
        height: 40px;
        width: 6px;
        padding-bottom: 15px;
        padding-left: 0;
    }
`;
const ArrowSlideLeft = styled.button`
    cursor: pointer;
    background: transparent;
    border: none;
    text-decoration: none;
    padding-left: 0;
    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        outline: none;
        box-shadow: none;
    }
`;
const ArrowSlideRight = styled.button`
    cursor: pointer;
    background: transparent;
    border: none;
    text-decoration: none;
    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        outline: none;
        box-shadow: none;
    }
`;
const DesktopOnly = styled.div`
    display:block;
    @media(max-width: 991px) {
        display:  none;
    }
`;
const MobileOnly = styled.div`
    display:block;
    @media(min-width: 991px) {
        display:  none;
    }
`;
class ShipmatesCarousel extends Component {
    state = {
        slideIndex: 0
    }

    changeSlide(diff) {
        const slideLength = this.props.locations.length
        const slideIndex = this.state.slideIndex + diff
        let index;
        index = slideIndex
        if (slideIndex < 0) {
            index = slideLength - 1
        }
        if (slideIndex > slideLength - 1) {
            index = 0
        }

        this.slider.slickGoTo(index)
    }



    render() {
        const {locations} = this.props

        const settings = {
            dots: false,
            arrows: false,
            infinite: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 0,
            swipe: false,
            draggable: false,
            swipeToSlide: false,
            centerMode: false,
            beforeChange: (current, next) => this.setState({ slideIndex: next }),
            responsive: [
                {
                  breakpoint: "991px",
                  settings: {
                      swipe: true,
                      swipeToSlide: true,
                  },
                },
              ],
          };

        return (
        <Shipmates>
            <Slider {...settings} ref={slider => (this.slider = slider)}>
                {locations.map((location, index) =>{
                    return (
                        <Shipmate {...location} index={index} key={index}/>
                    )
                })}
            </Slider>
            <DesktopOnly>
                <Zoom cascade duration={500} delay={750}>
                    <CarouselArrows>
                        <ArrowSlideLeft onClick={() => this.changeSlide(-1)}><LeftArrowStyled src={UpArrow} /></ArrowSlideLeft>
                        <ArrowSlideRight onClick={() => this.changeSlide(1)}><RightArrowStyled src={DownArrow} /></ArrowSlideRight>
                    </CarouselArrows>
                </Zoom>
            </DesktopOnly>
            <MobileOnly>
                <CarouselArrows>
                    <ArrowSlideLeft onClick={() => this.changeSlide(-1)}><LeftArrowStyled src={LeftArrow} /></ArrowSlideLeft>
                    <ArrowSlideRight onClick={() => this.changeSlide(1)}><RightArrowStyled src={RightArrow} /></ArrowSlideRight>
                </CarouselArrows>
            </MobileOnly>
        </Shipmates>);
    }
}

export default ShipmatesCarousel;
