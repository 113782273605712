import React, { useState, useEffect } from "react"
import styled from "styled-components"
import VoyageTitle from "./VoyageTitle"
import VoyageWhiteOverlay from "../../resources/images/home/voyage-white-overlay.png"
import VoyageWhiteOverlayMobile from "../../resources/images/home/voyage-white-overlay-mobile.png"
import BackgroundImage from "gatsby-background-image"
import { graphql, StaticQuery } from "gatsby"
import Play from "../../resources/images/home/play-video.png"
import Popup from "reactjs-popup"
import { relative } from "path"

const DesktopOnly = styled.div`
  display: none;
  @media (min-width: 769px) {
    display: block;
  }
  position: relative;
`
const MobileOnly = styled.div`
  display: block;
  @media (min-width: 769px) {
    display: none;
  }
`
const VoyageBackground = styled.div`
  background-color: #020913;
  width: 100%;
`
const VoyageImageContainer = styled.div`
  position: relative;
`
const VoyageBottomOverlay = styled.img`
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 141px;
  @media (min-width: 768px) {
    height: 219px;
  }
`
const OverlayTop = styled.div`
  position: absolute;
  top: -1px;
  left: 0;
  z-index: 1;
  background-image: linear-gradient(
    to top,
    rgba(0, 9, 20, 0),
    rgba(0, 9, 20, 1)
  );
  width: 100%;
  height: 117px;
  @media (min-width: 768px) {
    height: 275px;
    z-index: 1;
  }
`
const PlaceholderImage = styled(BackgroundImage)`
  min-width: 100%;
  min-height: 100%;
  background-size: cover;
`
const PlaceholderContainer = styled.div`
  height: 331px;
  width: 100vw;
  z-index: -1;
  @media (min-width: 768px) {
    height: 740px;
  }
`
const PlayButton = styled.button`
  cursor: pointer;
  height: 80px;
  width: 80px;
  background: transparent url(${Play}) no-repeat;
  background-size: 100%;
  border: none;
  border-radius: 0;
  -webkit-tap-highlight-color: transparent;
  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    outline: none;
    box-shadow: none;
  }
  position: absolute;
  top: calc(50% - 40px);
  left: calc(50% - 40px);
  z-index: 5;
  @media (max-width: 768px) {
    width: 64px;
    height: 64px;
  }
`
const Pulse = styled.span`
  height: 80px;
  width: 80px;
  position: absolute;
  top: calc(50% - 40px);
  left: calc(50% - 40px);
  z-index: 3;
  &:before {
    content: "";
    position: relative;
    display: block;
    width: 150%;
    height: 150%;
    box-sizing: border-box;
    margin-left: -25%;
    margin-top: -25%;
    border-radius: 100%;
    background-color: #9ddce2;
    animation: pulse-ring 2s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  }
  @keyframes pulse-ring {
    0% {
      transform: scale(0.33);
    }
    80%,
    100% {
      opacity: 0;
    }
  }
  @media (max-width: 768px) {
    width: 64px;
    height: 64px;
  }
`

const VideoBackground = styled.div`
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
  position: relative;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;

  }
`

const contentStyle = {
  width: "90%",
  position: "absolute",
  border: "none",
  height: "auto",
  overflow: "hidden",
  background: "transparent",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
}
const contentStyleMobile = {
  width: "90%",
  position: "absolute",
  border: "none",
  height: "auto",
  overflow: "hidden",
  background: "transparent",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
}
const overlayStyle = {
  overflowY: "auto",
}

const OurVoyage = () => {
  const [open, setOpen] = useState(false)
  const [width, setWidth] = useState()

  const handleModal = val => {
    if (val === "open") {
      setOpen(true)
    }
    if (val !== "open") {
      setOpen(false)
    }
  }

  const handleResize = () => {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      setWidth(window.innerWidth)
    }
  }, [])

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("resize", handleResize)
    }
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  })

  return (
    <StaticQuery
      query={graphql`
        query {
          background: file(relativePath: { eq: "home/video_placeholder.jpg" }) {
            childImageSharp {
              fluid(quality: 80, maxWidth: 2500) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={data => {
        const imageData = data.background.childImageSharp.fluid
        const voyageVideo = "https://player.vimeo.com/video/486822697/"
        return (
          <VoyageBackground>
            <VoyageTitle />
            <VoyageImageContainer>
              <OverlayTop />
              <DesktopOnly>
                <PlaceholderContainer>
                  <PlaceholderImage
                    fluid={imageData}
                    fadeIn={"soft"}
                    durationFadeIn={500}
                  />
                  <PlayButton onClick={() => handleModal("open")} />
                  <Pulse></Pulse>
                </PlaceholderContainer>
              </DesktopOnly>
              <MobileOnly>
                <PlaceholderContainer>
                  <PlaceholderImage
                    fluid={imageData}
                    fadeIn={"soft"}
                    durationFadeIn={500}
                  />
                  <PlayButton onClick={() => handleModal("open")} />
                  <Pulse></Pulse>
                </PlaceholderContainer>
              </MobileOnly>
              <DesktopOnly>
                <VoyageBottomOverlay src={VoyageWhiteOverlay} />
              </DesktopOnly>
              <MobileOnly>
                <VoyageBottomOverlay src={VoyageWhiteOverlayMobile} />
              </MobileOnly>
            </VoyageImageContainer>
            <Popup
              contentStyle={width > 768 ? contentStyle : contentStyleMobile}
              overlayStyle={overlayStyle}
              open={open}
              closeOnDocumentClick
              onClose={() => handleModal("close")}
            >
              <VideoBackground>
                <iframe
                  src={`${voyageVideo}?autoplay=1&loop=1&controls=1&muted=${width > 768 ? "0" : "1"}`}
                  title="Project Video"
                  frameBorder="0"
                  allow="fullscreen; autoplay"
                  allowFullScreen={true}
                />
              </VideoBackground>
            </Popup>
          </VoyageBackground>
        )
      }}
    />
  )
}

export default OurVoyage
