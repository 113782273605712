import React, { Component } from "react"
import styled from "styled-components"
import Layout from "../components/core/Layout"
import SEO from "../components/core/Seo"
import HomeHero from "../components/home/HomeHero"
import ProductIntro from "../components/home/ProductIntro"
import OurVoyage from "../components/home/OurVoyage"
import JoinTheCrew from "../components/home/JoinTheCrew"
import Foundation from "../components/home/Foundation"
import get from "lodash/get"
import PrivateRoute from "../router/PrivateRoute"

const LayoutStyled = styled(Layout)`
  margin: 0;
`

class IndexPage extends Component {
  render() {
    const path = this.props.location.pathname
    const data = get(this, "props.data")

    const {
      page,
      featured: featuredProducts,
      locations,
      IntroImg1,
      IntroImg2,
      JoinTheCrew1,
      JoinTheCrew2,
      JoinTheCrew3,
      JoinTheCrew4,
      JoinTheCrew5,
      JoinTheCrew6,
      MobileJoinTheCrew1,
      MobileJoinTheCrew2,
      MobileJoinTheCrew3,
      MobileJoinTheCrew4,
    } = data;

    const crewProps = {
      JoinTheCrew1, JoinTheCrew2, JoinTheCrew3, JoinTheCrew4, JoinTheCrew5, JoinTheCrew6,
      MobileJoinTheCrew1, MobileJoinTheCrew2, MobileJoinTheCrew3, MobileJoinTheCrew4
    }

    locations.nodes.sort((a, b) => {
      return (a.order === null)-(b.order === null) || +(a.order > b.order)||-(a.order < b.order);
    })

    const indexPage = page.nodes[0]

    return (
      <LayoutStyled>
        <SEO
          title={indexPage.metaTitle}
          description={indexPage.metaDescription}
          fullTitle={true}
          path={path}
        />
        <HomeHero heroVideo={indexPage.heroVideo} heroImage={indexPage.heroImage}/>
        <ProductIntro
          products={featuredProducts.edges.slice(0,3)}
          IntroImg1={IntroImg1}
          IntroImg2={IntroImg2}
        />
        <OurVoyage />
        <JoinTheCrew {...crewProps} />
        <Foundation locations={locations.nodes}/>
      </LayoutStyled>
    )
  }
}

function index(props) {
  return <PrivateRoute component={IndexPage} {...props} />
}

export default index

export const pageQuery = graphql`
  query HomeQuery {
    page: allContentfulHomepage(limit: 1) {
      nodes {
        metaTitle
        metaDescription
        heroImage {
          fluid(maxWidth: 1920, quality: 90) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        heroVideo
      }
    }
    featured: allShopifyProduct(filter: { tags: { in: ["Featured", "featured"] } }) {
      edges {
        node {
          id
          handle
          title
          handle
          description
          metafields {
            key
            value
          }
          images {
            id
            localFile {
              url
              childImageSharp {
                fluid(maxWidth: 720, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          id
          shopifyId
          handle
          title
          descriptionHtml
          description
          productType
          tags
          variants {
            id
            price
            shopifyId
            image {
              id
              localFile {
                extension
              }
            }
            title
            availableForSale
            selectedOptions {
              name
              value
            }
          }
          options {
            id
            values
            name
            shopifyId
          }
          priceRange {
            minVariantPrice {
              amount
              currencyCode
            }
          }
        }
      }
    }
    locations: allContentfulLocation {
      nodes {
        name
        city
        order
        description {
          json
        }
        image {
          fluid(quality: 80, maxWidth: 720) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
    }
    IntroImg1: file(relativePath: { eq: "home/intro-1.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 720) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    IntroImg2: file(relativePath: { eq: "home/intro-opt.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 720) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    JoinTheCrew1: file(relativePath: { eq: "home/join-01.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 562) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    JoinTheCrew2: file(relativePath: { eq: "home/join-02.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 814) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    JoinTheCrew3: file(relativePath: { eq: "home/join-03.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 396) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    JoinTheCrew4: file(relativePath: { eq: "home/join-04.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 474) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    JoinTheCrew5: file(relativePath: { eq: "home/join-05.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 814) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    JoinTheCrew6: file(relativePath: { eq: "home/join-06.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 762) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    MobileJoinTheCrew1: file(relativePath: { eq: "home/mobile-join-01.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 288) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    MobileJoinTheCrew2: file(relativePath: { eq: "home/mobile-join-02.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 408) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    MobileJoinTheCrew3: file(relativePath: { eq: "home/mobile-join-03.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 400) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    MobileJoinTheCrew4: file(relativePath: { eq: "home/mobile-join-04.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 236) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`
