import React from "react"
import styled from "styled-components"
import { Row, Col } from "styled-bootstrap-grid"
import fonts from "../../styles/fonts"
import { Fade } from "react-reveal"
import Button from "../core/Button"
import Img from "gatsby-image"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

const ColStyled = styled(Col)`
    padding-left: 0px;
    padding-right: 0px;
        @media(min-width: 991px) {
            display: ${props => props.mobileonly ? "none" : "block"}
        }
        @media(max-width: 991px) {
            display: ${props => props.dektoponly ? "none" : "block"}
        }
`
const RowStyled = styled(Row)`
    margin-left: 0px;
    margin-right: 0px;
    width: ${props => props.fullwidth ? "100%" : ""};
    height: ${props => props.fullwidth ? "100%" : ""};
    padding: 0 33px;
`

const SingleShipmate = styled.div``


const ShipmatesImgContainer = styled.div`
    width: 100%;
`
const ShipmatesImgStyled = styled.img`
    height: 606px;
    margin-top: -12px;
`
const ShipmatesTitle = styled.div`
    color: white;
    text-transform: uppercase;
    ${fonts.swissBlackExtended};
    letter-spacing: 3px;
    font-size: 24px;
    padding-bottom: 30px;
    @media(min-width: 991px) {
        margin-left: -85px;
        font-size: 42px;
        padding-bottom: 40px;
    }
`
const ShipmatesLocation = styled.div`
    color: white;
    font-size: 15px;
    line-height: 21px;
    ${fonts.gilroyMedium};
    padding-bottom: 15px;
    @media(min-width: 991px) {
        padding-bottom: 20px;
    }
`
const ShipmatesDescription = styled.div`
    ${fonts.gilroyRegular};
    font-size: 15px;
    line-height: 21px;
    color: #9CA8B5;
    padding-bottom: 35px;
   padding-left: 15px;
   padding-right: 15px;
    @media(min-width: 991px) {
        padding-left: 0px;
        padding-right: 110px;
        padding-bottom: 45px;
    }
`
const ShipmatesTextContainer = styled.div`
    text-align : center;    
    padding-top: 50px;
    margin: 0;
    @media(min-width: 991px) {
        margin-left: -10px;
        text-align: left;
        padding-top: 95px;
    }
`
// const ShipmatesButton = styled.button`
//     cursor: pointer;
//     width: 100%;
//     border: 1px solid #9DDCE2;
//     background: transparent;
//     color: white;
//     text-transform: uppercase;
//     font-size: 12px;
//     ${fonts.gilroyBold};
//     padding-top: 15px;
//     padding-bottom: 15px;
//     letter-spacing: 3px;
//     transition: background .5s, border .5s, color .5s;
//     -webkit-transition: background .5s, border .5s, color .5s;
//     &:hover,
//     &:active,
//     &:focus {
//         text-decoration: none;
//         outline: none;
//         box-shadow: none;
//         background: white;
//         border: 1px solid white;
//         color: #9DDCE2;
//     }
// `
// const ShipmatesButtonContainer = styled.div`
//     position: relative;
//     width: 306px;
//     @media(max-width: 768px) {
//         margin: auto;
//     }
// `
// const BorderStyled = styled.div`
//     position: absolute;
//     top: 4px;
//     right: -5px;
//     background-color: transparent;
//     border-bottom: 5px solid #9DDCE2;
//     border-right: 5px solid #9DDCE2;
//     height: 43px;
//     width: 299px;
//     z-index: -1;
// `
const ShipmatesImgSmallStyled = styled.img`
    height: 460px;
    margin-top: 75px;
    width: 100%;
    object-fit: cover;
    @media(max-width: 362px) {
        height: 380px;
    } 
`

const StyledImg = styled(Img)`
  max-height: 606px;

  @media(min-width: 991px) {
    max-width: 458px;

  }
`

const Shipmates = (props) => {
  const { image, name, city, description } = props

  return (<SingleShipmate>
      <RowStyled>
        <ColStyled lg={7}>
          <ShipmatesImgContainer>
              <Fade bottom duration={1000}>
                <StyledImg fluid={image.fluid} alt={name}/>
              </Fade>
          </ShipmatesImgContainer>
        </ColStyled>
        <ColStyled lg={5}>
          <ShipmatesTextContainer>
            <Fade duration={750}><ShipmatesTitle>Shipmates</ShipmatesTitle></Fade>
            <Fade duration={750}><ShipmatesLocation>{name}, {city}</ShipmatesLocation></Fade>
            <Fade duration={750}><ShipmatesDescription>{documentToReactComponents(description.json)}</ShipmatesDescription></Fade>
            <Fade duration={750}>
              <Button text={"Find your nearest shipmate"} link={"/shipmates"} shipmates/>
            </Fade>
          </ShipmatesTextContainer>
        </ColStyled>
      </RowStyled>
    </SingleShipmate>
  )
}

export default Shipmates
