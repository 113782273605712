import React from 'react';
import styled from 'styled-components';
import { Row, Col, Container } from 'styled-bootstrap-grid';
import fonts from '../../styles/fonts';
import LineImg from '../../resources/images/horizontal-broken-line.svg';
import SocialMediaLinks from './SocialMediaLinks';
import { Fade } from 'react-reveal';
import Img from "gatsby-image"


const DesktopOnly = styled.div`
    display:block;
    @media(max-width: 1037px) {
        display:  none;
    }
`;
const MobileOnly = styled.div`
    display:block;
    @media(min-width: 1038px) {
        display:  none;
    }
`;
const JoinTheCrewBackground = styled.div`
    background-color: white;
    width: 100vw;
`;
const JoinTheCrewContainer = styled(Container)`
    position: relative;
    text-align: center;
    padding-top: 60px;
    padding-bottom: 20px;
    @media(max-width: 768px) {
        padding: 0px;
        padding-top: 25px;
    }
`;
const ColStyled = styled(Col)`
    padding-left: 0px;
    padding-right: 0px;
        @media(min-width: 768px) {
            display: ${props => props.mobileonly ? "none" : "block"}
        }
        @media(max-width: 768px) {
            display: ${props => props.dektoponly ? "none" : "block"}
        }
    `;
const RowStyled = styled(Row)`
    margin-left: 0px;
    margin-right: 0px;
    width: ${props => props.fullwidth ? "100%" : ""};
    height: ${props => props.fullwidth ? "100%" : ""};
`;
const JoinTheCrewTitle = styled.div`
        color: #242E3B;
        ${fonts.swissBlackExtended};
        font-size: 24px;
        text-transform: uppercase;
        padding-bottom: 35px;
        padding-top: 15px;
        letter-spacing: 3px;
        @media(min-width: 768px) {
            font-size: 42px;
            letter-spacing: 3px;
            padding-top: 0px;
        }
`;
const LineStyled = styled.img`
    padding-top: 45px;
    padding-bottom: 25px;
    @media(min-width: 768px) {
        padding-top: 60px;
        padding-bottom: 40px;
    }
`;
const JoinTheCrewText = styled.div`
    color: #9CA8B5;
    font-size: 15px;
    line-height: 21px;
    ${fonts.gilroyRegular};
    @media(max-width: 768px){
        padding-left: 20px;
        padding-right: 20px;
    }
`;
const FirstRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    padding-top: ${props => props.mobile ? "45px" : "120px"};
`;

const SecondRow = styled.div`
    padding-top: ${props => props.mobile ? "8px" : "20px"};
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
`;
const FirstRow1Styled = styled.div`
    height: 382px;
    padding-right: 20px;
    width: 281px;
`;
const FirstRow2Styled = styled.div`
    height: 407px;
    padding-right: 20px;
    width: 407px;
`;
const FirstRow3Styled = styled.div`
    height: 198px;
    width: 198px;
`;
const SecondRow1Styled = styled.div`
    height: 308px;
    position: relative;
    z-index: 10;
    margin-right: -41px;
    width: 237px;
`;
const SecondRow2Styled = styled.div`
    height: 407px;
    padding-left: 20px;
    width: 407px;
`;
const SecondRow3Styled = styled.div`
    height: 281px;
    padding-left: 20px;
    width: 381px;
`;
const SocialMediaContainer = styled.div`
    height: 198px;
    width: 360px;
    border-bottom: 5px solid #9DDCE2;
    position: absolute;
    z-index: 11;
    background: white;
    bottom: 315px;
    left: 367px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
`;

const MediaTitle = styled.div`
    color: #9CA8B5;
    ${fonts.gilroyBold};
    font-size: 12px;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 3px;
    padding-top: ${props => props.mobile ? "55px" : "10px"};
    padding-bottom: ${props => props.mobile ? "35px" : "0px"};
`;
const FirstRow1StyledMobile = styled.div`
    height: 152px;
    margin-right: -19px;
    position: relative;
    z-index: 11;
    width: 144px;
    overflow: visible;
    img {
        object-fit: contain !important;
    }
    @media(max-width: 346px) {
        height: 142px;
    }
`;
const FirstRow2StyledMobile = styled.div`
    height: 171px;
    width: 204px;
`;
const SecondRow1StyledMobile = styled.div`
    height: 171px;
    padding-right: 8px;
    width: 171px;
`;
const SecondRow2StyledMobile = styled.div`
    height: 118px;
    width: 118px;
    @media(max-width: 346px) {
        height: 110px;
    }
`;
const Space = styled.div`
    padding: 20px;
`;

const JoinTheCrew = (props) => {
    const style = "dark";
    const jointhecrew = "true";
    return (<JoinTheCrewBackground>
        <JoinTheCrewContainer>
            <RowStyled>
                <ColStyled><LineStyled src={LineImg} /></ColStyled>
                <ColStyled><Fade duration={250}><JoinTheCrewTitle>Join the crew</JoinTheCrewTitle></Fade></ColStyled>
                <ColStyled>
                    <Fade duration={500} delay={250}><JoinTheCrewText>
                        <DesktopOnly><p>Behind the scenes action, news on up and coming events, and other awesome</p>
                            <p>updates. Follow our social accounts to make sure you never miss a thing!</p>
                        </DesktopOnly>
                        <MobileOnly>
                            <p>Behind the scenes action, news on up and coming events, and other awesome updates. Follow our social accounts to make sure you never miss a thing!</p>
                        </MobileOnly>
                    </JoinTheCrewText></Fade>
                </ColStyled>
                <ColStyled>
                    <DesktopOnly>
                        <RowStyled>
                            <ColStyled>
                                <Fade duration={500}><FirstRow>
                                    <FirstRow1Styled>
                                        <Img fluid={props.JoinTheCrew1.childImageSharp.fluid} />
                                    </FirstRow1Styled>
                                    <FirstRow2Styled>
                                        <Img fluid={props.JoinTheCrew2.childImageSharp.fluid} />
                                    </FirstRow2Styled>
                                    <FirstRow3Styled>
                                        <Img fluid={props.JoinTheCrew3.childImageSharp.fluid} />
                                    </FirstRow3Styled>
                                </FirstRow></Fade>
                            </ColStyled>
                            <ColStyled>
                                <Fade duration={500}> <SecondRow>
                                    <SecondRow1Styled>
                                        <Img fluid={props.JoinTheCrew4.childImageSharp.fluid} />
                                    </SecondRow1Styled>
                                    <SecondRow2Styled>
                                        <Img fluid={props.JoinTheCrew5.childImageSharp.fluid} />
                                    </SecondRow2Styled>
                                    <SecondRow3Styled>
                                        <Img fluid={props.JoinTheCrew6.childImageSharp.fluid} />
                                    </SecondRow3Styled>
                                </SecondRow></Fade>
                            </ColStyled>
                        </RowStyled>
                        <Fade duration={750} >
                            <SocialMediaContainer>
                                <MediaTitle>
                                    Go with the flow
                                    </MediaTitle>
                                    <SocialMediaLinks iconColor={style} jointhecrew={jointhecrew}/>
                            </SocialMediaContainer>
                        </Fade>
                    </DesktopOnly>
                    <MobileOnly>
                        <RowStyled>
                            <ColStyled>
                                <Fade duration={500}> <FirstRow mobile>
                                    <FirstRow1StyledMobile>
                                        <Img fluid={props.MobileJoinTheCrew1.childImageSharp.fluid} />
                                    </FirstRow1StyledMobile>
                                    <FirstRow2StyledMobile>
                                        <Img fluid={props.MobileJoinTheCrew2.childImageSharp.fluid} />
                                    </FirstRow2StyledMobile>
                                </FirstRow> </Fade>
                                <Fade duration={500}><SecondRow mobile>
                                    <SecondRow1StyledMobile >
                                        <Img fluid={props.MobileJoinTheCrew3.childImageSharp.fluid} />
                                    </SecondRow1StyledMobile>
                                    <SecondRow2StyledMobile >
                                        <Img fluid={props.MobileJoinTheCrew4.childImageSharp.fluid} />
                                    </SecondRow2StyledMobile>
                                </SecondRow></Fade>
                            </ColStyled>
                            <ColStyled><MediaTitle mobile >Go with the flow</MediaTitle></ColStyled>
                            <ColStyled>
                                <SocialMediaLinks iconColor={style} jointhecrew={jointhecrew} />
                                <Space></Space>
                            </ColStyled>
                        </RowStyled>
                    </MobileOnly>
                </ColStyled>
            </RowStyled>
        </JoinTheCrewContainer>
    </JoinTheCrewBackground>);
}

export default JoinTheCrew;
