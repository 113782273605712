import React from 'react';
import styled from 'styled-components';
import fonts from '../../styles/fonts';
import { Fade } from 'react-reveal';


const StyledTitle = styled.h1`
    margin-top: 310px;
    text-align: center;
    vertical-align: middle;
    padding-left: 5px;
    padding-right: 5px;
    text-transform: uppercase;   
`;
const WhiteLine = styled.div`
    color: white;
    letter-spacing: 4px;
    margin-bottom: 0;
    ${fonts.swissBlackExtended};
    font-size: 45px;
    cursor: default;
    @media(min-width: 768px) {
        font-size: 77px; 
    }
    @media(max-width: 346px) {
        font-size: 44px;
    }
`;
const OutlinedLine = styled.div`
    font-size: 47px;
    color: transparent;
    letter-spacing: 4px;
	-webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: white;
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 20px;
    padding-right: 20px;
    cursor: default;
    ${fonts.swissBlackExtended};
    @media (min-width: 768px) {
        font-size: 77px; 
    }
    @media(max-width: 346px) {
        font-size: 46px;
    }
`;

const Title = () => {
    return (<StyledTitle>
        <Fade duration={750} delay={500}>
            <WhiteLine>Flavour Favours</WhiteLine>
        </Fade>
        <Fade duration={750} delay={750}>
            <OutlinedLine>The Brave</OutlinedLine>
        </Fade>
    </StyledTitle>);
}

export default Title;