import React from 'react';
import styled from 'styled-components';
import VoyageLetter1 from '../../resources/images/home/voyage-letter-1.svg';
import VoyageLetter2 from '../../resources/images/home/voyage-letter-2.svg';
import VoyageLetter3 from '../../resources/images/home/voyage-letter-3.svg';
import VoyageLetter4 from '../../resources/images/home/voyage-letter-4.svg';
import VoyageLetter5 from '../../resources/images/home/voyage-letter-5.svg';
import VoyageLetter6 from '../../resources/images/home/voyage-letter-6.svg';
import VoyageLetter7 from '../../resources/images/home/voyage-letter-7.svg';
import VoyageLetter8 from '../../resources/images/home/voyage-letter-8.svg';
import VoyageLetter9 from '../../resources/images/home/voyage-letter-9.svg';
import { Zoom } from 'react-reveal';


const VoyageTitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: 60px;
    margin-bottom : 0px;
    position: relative;
    z-index: 10;
    @media(min-width: 768px) {
        margin-bottom : -10px;
        padding-top: 200px;
    }
`;
const VoyageLetter = styled.img`
    height: 20px;
    z-index: 11;
    @media (min-width: 362px) and (max-width: 768px) {
        height: 29px;
    };
    @media (min-width: 768px) and (max-width: 1128px) {
        height: 62px;
    };
    @media(min-width: 1128px) {
        height: 94px;
    }
`;

const VoyageTitle = () => {
    return (<VoyageTitleContainer>
        <Zoom cascade duration={3000}>
            <VoyageLetter src={VoyageLetter1} />
            <VoyageLetter src={VoyageLetter2} />
            <VoyageLetter src={VoyageLetter3} />
            <VoyageLetter src={VoyageLetter4} />
            <VoyageLetter src={VoyageLetter5} />
            <VoyageLetter src={VoyageLetter6} />
            <VoyageLetter src={VoyageLetter7} />
            <VoyageLetter src={VoyageLetter8} />
            <VoyageLetter src={VoyageLetter9} />
        </Zoom>
    </VoyageTitleContainer>)
}

export default VoyageTitle;