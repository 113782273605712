import React from 'react';
import styled from 'styled-components';
import FoundationLetter1 from '../../resources/images/home/foundation-letter-1.svg';
import FoundationLetter2 from '../../resources/images/home/foundation-letter-2.svg';
import FoundationLetter3 from '../../resources/images/home/foundation-letter-3.svg';
import FoundationLetter4 from '../../resources/images/home/foundation-letter-4.svg';
import FoundationLetter5 from '../../resources/images/home/foundation-letter-5.svg';
import FoundationLetter6 from '../../resources/images/home/foundation-letter-6.svg';
import FoundationLetter7 from '../../resources/images/home/foundation-letter-7.svg';
import FoundationLetter8 from '../../resources/images/home/foundation-letter-8.svg';
import FoundationLetter9 from '../../resources/images/home/foundation-letter-9.svg';
import FoundationLetter10 from '../../resources/images/home/foundation-letter-10.svg';
import { Zoom } from 'react-reveal';

const FoundationTitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: 200px;
    margin-bottom : 0px;
    position: relative;
    z-index: 10;
    @media(min-width: 586px) {
        position: absolute;
        bottom: 0;
        width: 100%;
    }
`;
const FoundationLetter = styled.img`
    height: 20px;
    z-index: 11;
    @media (min-width: 362px) and (max-width: 768px) {
        height: 29px;
    };
    @media (min-width: 768px) and (max-width: 1128px) {
        height: 62px;
    };
    @media(min-width: 1128px) {
        height: 94px;
    }
`;

const FoundationTitle = () => {
    return (<FoundationTitleContainer>
        <Zoom cascade duration={3000}>
            <FoundationLetter src={FoundationLetter1} />
            <FoundationLetter src={FoundationLetter2} />
            <FoundationLetter src={FoundationLetter3} />
            <FoundationLetter src={FoundationLetter4} />
            <FoundationLetter src={FoundationLetter5} />
            <FoundationLetter src={FoundationLetter6} />
            <FoundationLetter src={FoundationLetter7} />
            <FoundationLetter src={FoundationLetter8} />
            <FoundationLetter src={FoundationLetter9} />
            <FoundationLetter src={FoundationLetter10} />
        </Zoom>
    </FoundationTitleContainer>)
}

export default FoundationTitle;
