import React, { Component } from 'react';
import styled from 'styled-components';
import { Row, Col, Container } from 'styled-bootstrap-grid';
import FoundationImg from '../../resources/images/home/foundation-intro-opt.jpg' ;
import FoundationTitle from './FoundationTitle';
import FoundationSmallImg from '../../resources/images/home/foundation-small-img.png';
import fonts from '../../styles/fonts';
import FoundationSmallImgMobile from '../../resources/images/home/fondation-small-img-mobile.png';
import MobileBottomOverlay from '../core/MobileBottomOverlay';
import ShipmatesBackgroundImg from '../../resources/images/home/shipmates-background.jpg';
import ShipmatesBackgroundMobileImg from '../../resources/images/home/shipmates-background-mobile.jpg';
import ShipmatesCarousel from '../shipmates/ShipmatesCarousel';
import { Fade } from 'react-reveal';
import CountUp from 'react-countup';
import WaitroseLogo from '../../resources/images/home/supporters-waitrose.svg';
import BarbourLogo from '../../resources/images/home/supporters-barbour.svg';
import LandRoverLogo from '../../resources/images/home/supporters-land-rover.svg';
import HunterLogo from '../../resources/images/home/supporters-hunter.svg';
import JackWillsLogo from '../../resources/images/home/supporters-jackwills.svg';

import CompaniesHouseLogo from "../../resources/images/home/companies-house-white.png";
import WavelengthLogo from "../../resources/images/home/wavelength-logo.png";
import ShoreLogo from "../../resources/images/home/shore-white.png";
import SurfersLogo from "../../resources/images/home/surfers-against-sewage-hhite.png";
import LifeboatsLogo from "../../resources/images/logos/lifeboats-logo.svg"

const FoundationBackground = styled.div`
    background-color: #020913;
    width: 100%;
`;
const FoundationBackgroundImage = styled.div`
    position: relative;
    background: url(${FoundationImg}) no-repeat;  
    background-size: 100% auto;
    height:  300px;
    width: 100%;
    @media(min-width: 586px) {
        height: 400px;
        background-size: cover;
        margin-bottom: 76px;
    }
    @media(min-width: 768px) {
        height:  800px;
        width: 100%;
        margin-bottom: 100px;
    }
    @media(min-width: 992px) {
        margin-bottom: 198px;
    }
`;
const FoundationContainer = styled(Container)`
    padding-bottom: 50px;
    @media(max-width: 768px) {
        padding: 0;
    }
`;
const ColStyled = styled(Col)`
    padding-left: 0;
    padding-right: 0;
        @media(min-width: 768px) {
            display: ${props => props.mobileonly ? "none" : "block"}
        }
        @media(max-width: 768px) {
            display: ${props => props.dektoponly ? "none" : "block"}
        }
    `;
const RowStyled = styled(Row)`
    margin-left: 0;
    margin-right: 0;
    width: ${props => props.fullwidth ? "100%" : ""};
    height: ${props => props.fullwidth ? "100%" : ""};
`;
const FoundationImgContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
`;
const FoundationImgStyled = styled.img`
    height: 481px;
    @media(min-width: 768px) {
        margin-top: 56px;
    }
    
    @media(min-width: 1200px) {
        padding-right: 100px;
    }
`;
const FoundationTextContainer = styled.div`
    margin-right: 0;
    z-index: 10;
    padding-left: 20px;
    position: relative;
    @media(min-width: 768px) {
        padding-left: 105px;
        margin-right: -160px;
        padding-left: 25px;
    }
`;
const FoundationDescription = styled.div`
        color: rgb(158, 168, 180);
        ${fonts.gilroyRegular};
        font-size: 15px;
        line-height: 21px;
        padding-right: 40px;
        padding-left: 10px;
        margin-top: 0;
        padding-bottom: 60px;
        font-weight: lighter;
        @media(min-width: 992px) {
            padding-right: 250px;
        }
        @media(min-width: 1200px) {
            padding-right: 450px;
        }
`;
const FoundationTitleSmall = styled.div`
    color: white;
    font-size: 30px;
    letter-spacing: 3px;
    text-transform: uppercase;
    ${fonts.swissBlackExtended};
    padding-bottom: 15px;
    @media(min-width: 768px) {
        font-size: 63px;
        padding-bottom: 30px;
        letter-spacing: 6px;
    }
`;
const FoundationSum = styled.div`
    color: transparent;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #9DDCE2;
    text-transform: uppercase;
    ${fonts.swissBlackExtended};
    font-size: 48px;
    padding-bottom: 8px;
    @media(min-width: 768px) {
        font-size: 82px;
        padding-bottom: 15px;
    }
`;
const FoundationUnderline = styled.div`
        height: 7px;
        width: 257px;
        background-color: #9DDCE2;
        @media(min-width: 768px) {
            height: 10px;
            width: 428px;
        }
`;
const FoundationImgMobileStyled = styled.img`
        height: 326px;
        margin-top: -100px;
`;
const DesktopOnly = styled.div`
    display:block;
    @media(max-width: 768px) {
        display:  none;
    }
`;
const MobileOnly = styled.div`
    display:block;
    @media(min-width: 769px) {
        display:  none;
    }
`;
const ShipmatesBlockBackground = styled.div`
    background: url(${ShipmatesBackgroundImg}) no-repeat;  
    width: 100%;
    min-height: 729px;
    background-size: 100% 100%;
    position: relative;
    padding-bottom: 0px;
    @media(max-width: 768px){
        padding-bottom: 100px;
        min-height: 859px;
    }
    @media(max-width: 408px){
        background: url(${ShipmatesBackgroundMobileImg}) no-repeat;  
    }
`;
const ShipmatesContainer = styled(Container)`
    padding-bottom: 50px;
    padding-top: 130px;
    @media(max-width: 768px) {
        padding: 0;
    }
`;
const SupportersList = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 80px;
`;
const SupportersImg = styled.img`
    max-height: 76px;
    max-width: 120px;
    padding-bottom: 25px;
    @media(min-width: 768px){
        max-height: 53px;
        min-height: 32px;
    }
    @media(min-width: 992px){
        max-height: 80px;
        min-height: 64px;
        max-width: 160px;
    }
`;
const SupportersContainer = styled(Container)`
    text-align: center;
    padding-top: 145px;
    @media(max-width: 768px) {
        padding: 0;
        padding-bottom: 40px;
    }
`;
const SupportersTitle = styled.div`
    ${fonts.gilroyBold};
    font-size: 12px;
    letter-spacing: 3px;
    color: white;
    text-transform: uppercase;
    padding-bottom: 110px;
    @media(max-width: 768px) {
        padding-bottom: 60px;
    }
`;

class Foundation extends Component {
    state = {
        countUpInView: false
    }

    changeVisibility = () => {
        this.setState({
            countUpInView: true
        })
    }

    render() {
        const {locations} = this.props;
        return (<FoundationBackground>
            <FoundationBackgroundImage>
                <FoundationTitle />
                <MobileBottomOverlay />
            </FoundationBackgroundImage>
            <FoundationContainer>
                <RowStyled>
                    <ColStyled md={7}>
                        <FoundationTextContainer>
                            <Fade duration={750}>
                                <FoundationDescription>
                                    Our respect for the sea and all who risk life and limb to chart their own course knows no bounds. That’s why we’ve set up the Lost at Sea Foundation, a charitable trust that supports causes close to our heart.
                            </FoundationDescription>
                            </Fade>
                            <Fade duration={750} >
                                <FoundationTitleSmall>
                                    Total raised to date
                            </FoundationTitleSmall>
                            </Fade>
                            <Fade onReveal={() => this.changeVisibility()}>
                                <FoundationSum>
                                    <CountUp start={0} end={this.state.countUpInView ? 1674 : 0} separator="," duration={2} prefix="£"></CountUp>
                                </FoundationSum>
                            </Fade>
                            <Fade duration={750} >
                                <FoundationUnderline />
                            </Fade>
                        </FoundationTextContainer>
                    </ColStyled>
                    <ColStyled md={5}>
                        <FoundationImgContainer>
                            <DesktopOnly><Fade bottom duration={1000} ><FoundationImgStyled src={FoundationSmallImg} /></Fade></DesktopOnly>
                            <MobileOnly><FoundationImgMobileStyled src={FoundationSmallImgMobile} /></MobileOnly>
                        </FoundationImgContainer>
                    </ColStyled>
                </RowStyled>
            </FoundationContainer>
            <ShipmatesBlockBackground>
                <ShipmatesContainer>
                    <ShipmatesCarousel locations={locations}/>
                </ShipmatesContainer>
            </ShipmatesBlockBackground>
            <SupportersContainer>
                <RowStyled>
                    <ColStyled><Fade duration={500}><SupportersTitle>Our merry band of supporters</SupportersTitle></Fade></ColStyled>
                    <ColStyled>

                        <DesktopOnly>
                            <SupportersList>
                                <Fade duration={1000} >
                                    <SupportersImg src={CompaniesHouseLogo} alt={"Companies House Logo"}/>
                                    <SupportersImg src={LifeboatsLogo} alt={"Lifeboats Logo"}/>
                                    <SupportersImg src={ShoreLogo} alt={"Shore Logo"}/>
                                    <SupportersImg src={SurfersLogo} alt={"Surfers Logo"}/>
                                    <SupportersImg src={WavelengthLogo} alt={"Finisterre Logo"}/>
                                </Fade>
                            </SupportersList>
                        </DesktopOnly>

                        <MobileOnly>
                            <RowStyled fullwidth>
                                <ColStyled xs={6} sm={6}>
                                    <SupportersImg src={CompaniesHouseLogo} alt={"Companies House Logo"}/>
                                </ColStyled>
                                <ColStyled xs={6} sm={6}>
                                    <SupportersImg src={LifeboatsLogo} alt={"Lifeboats Logo"}/>
                                </ColStyled>
                            </RowStyled>
                            <RowStyled fullwidth>
                                <ColStyled xs={6} sm={6}>
                                    <SupportersImg src={ShoreLogo} alt={"Shore Logo"}/>
                                </ColStyled>
                                <ColStyled xs={6} sm={6}>
                                    <SupportersImg src={SurfersLogo} alt={"Surfers Logo"}/>
                                </ColStyled>
                            </RowStyled>
                            <RowStyled>
                                <ColStyled>
                                    <SupportersImg src={WavelengthLogo} alt={"Finisterre Logo"}/>
                                </ColStyled>
                            </RowStyled>
                        </MobileOnly>
                    </ColStyled>
                </RowStyled>
            </SupportersContainer>
        </FoundationBackground>);
    }
}

export default Foundation;
